<template>
    <div class="city">
        <Navbar/>
        <Reims/>
    </div>
</template>
<script>
import Navbar from '@/components/common/Navbar.vue'
import Reims from '@/components/city/Reims.vue'
export default {
    components: {
        Navbar,
        Reims
    }
}
</script>