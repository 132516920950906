<template>
    <Introduction :class="'background-reims'">
        <template #title>
            Реймс - город французский королей и родина шампанского.
        </template>
    </Introduction>

    <Main>
        <template #current>
            Реймс
        </template>

        <template #youtube>
            <iframe width="450" height="315" src="https://www.youtube.com/embed/EzS34iMgqyM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </template>

        <template #pricing>
            <div class="case">
                                    <div class="title">
                                        <h5>РЕСТОРАНЫ</h5>
                                    </div>
                                    <div class="details">
                                        <ul>
                                            <li>Стоимость обеда/ужина в недорогом ресторане на 1 человека - 12 евро</li>
                                            <li>Стоимость обеда/ужина на двоих в ресторане среднего уровня (обед/ужин из 3-х блюд) - 40 евро</li>
                                            <li>McMeal в Макдональдс - 8 евро</li>
                                            <li>Капучино - 3,25 евро</li>
                                            <li>Местное пиво - 5.25 (0,5 литра)</li>
                                        </ul>
                                    </div>
            </div>
            <div class="case">
                <div class="title">
                    <h5>МАГАЗИНЫ</h5>
                </div>
                <div class="details">
                    <ul>
                        <li>Молоко (1 литр) - 0,77 евро</li>
                        <li>Буханка белого хлеба (500 гр) - 1,22 евро</li>
                        <li>Рис (1 кг) - 1 евро</li>
                        <li>Яйца (12 шт) - 2,87 евро</li>
                        <li>Местный сыр (1 кг) - 7,50 евро</li>
                        <li>Филе курицы (1 кг) - 9,50 евро</li>
                        <li>Яблоки (1 кг) - 2,41 евро</li>
                        <li>Картофель (1 кг) - 1,53 евро</li>
                        <li>Бутылка вина (средний уровень) - 6,50 евро</li>
                        <li>Местное пиво (0,5 литра) - 1,52 евро</li>
                        <li>Вода (1,5 литра) - 0,34 евро</li>
                    </ul>
                </div>
            </div>
            <div class="case">
                <div class="title">
                    <h5>ТРАНСПОРТ</h5>
                </div>
                <div class="details">
                    <ul>
                        <li>Билет в 1 конец - 1.60 евро</li>
                        <li>Абонемент на месяц - 30 евро</li>
                        <li>Расстояние до Парижа - 45 минут на скоростном поезде. Цена билета - 20-40 евро. </li>
                        <li>Расстояние до Парижа  - 1 час 10 минут на машине. Цена BlaBlaCar - 6 евро.</li>
                    </ul>
                </div>
            </div>
            <div class="case">
                <div class="title">
                    <h5>Развлечения </h5>
                </div>
                <div class="details">
                    <ul>
                        <li>Фитнес-клуб, абонемент на месяц - 21,67 евро</li>
                        <li>Билет в кино - 10 евро</li>
                    </ul>
                </div>
            </div>
            <div class="case">
                <div class="title">
                    <h5>Проживание</h5>
                </div>
                <div class="details">
                    <ul>
                        <li>1-комнатные апартаменты в центре города - 466 евро</li>
                        <li>3-комнатные апартаменты в центре города - 1300 евро</li>
                    </ul>
                </div>
            </div>
        </template>

        <template #intro>
            <p>
                Реймс — город и коммуна на северо-востоке Франции, расположенный в департаменте Марна в регионе Гранд-Эст. Является 12-м городом по численности населения во Франции и насчитывает 183.000 жителей.
            </p>
            <p>
                Реймс находится на расстоянии примерно 130 км от Парижа, 157 км от Меца, 168 км от Лилля и в 282 км от Страсбурга.
            </p>
            <p>
                Реймс называют «городом королей», здесь крестили короля Хлодвига I и на протяжении более десяти столетий, короновалось множество королей Франции, начиная с Людовика I Благочестивого в 816 году и вплоть до Карла X в 1825 году. Реймский собор - самая главная достопримечательность Реймса, которая занимает особое положение во всей французской истории и включена в список объектов Всемирного наследия ЮНЕСКО
            </p>
            <p>
                Не все знают, но герои романа Виктора Гюго “Собор Парижской Богоматери” Квазимодо и Эсмеральда жили в Реймсе, а не в Париже.
            </p>
            <p>
                Идеально расположенный во Франции, а также в самом сердце Европы, Реймс, стал крупным промышленным центром в таких сферах как фармацевтика, здравоохранение, пищевая промышленность, биотехнологии, вина и спиртные напитки.
            </p>
            <p>
                Близость к Парижу делает его привлекательным в плане инвестиций. Компании открывают в Реймс офисы, производственные площадки, сервисные центры, call-центры и даже, в последнее время, телевизионные и музыкальные студии звукозаписи.
            </p>
            <p>
                И, конечно же, Реймс - это родина шампанского. Здесь находятся самые известные дома шампанского (Lanson, Pommery, Mumm, Veuve Cliquot, Ruinart, Krug, Taittinger и многие другие).
            </p>
        </template>

        <template #logement>
            <p>1-комнатные апартаменты в центре города - 466 евро</p>
            <p>3-комнатные апартаменты в центре города - 1300 евро</p>
            <p>Районы, которые стоит избегать : Orgeval, Pont de witry, Wilson, Croix rouge, Trois Fontaines</p>
            <p>Районы, где стоит жить:  Le centre-ville ,  Le quartier Clairmarais,  Le quartier Cathédrale et Saint-Remi, La place du Forum, Le cours d’Erlon et Le cours Langle и другие.</p>
            <p>Город Реймс умеет высокий рейтинг безопасности - 64.52</p>
            <p>Примерный бюджет на студенческую резиденцию в Реймс - 350-500 евро/месяц ( не включая помощь от CAF)</p>
        </template>

        <template #culture>
            <p>В Реймсе можно вести активную культурную жизнь.</p>
            <p>В городе работают площадки для выступлений (La Comédie, La Cartonnerie, Le Manège de Reims) и кабаре, множество тематических баров, импровизационных клубов, два всемирно признанных театра, выставочный центр, конференц-центр, несколько музеев (например, изобразительных искусств, автомобилей, Palais du Tau), есть также фестивали, организуемые в течение года.</p>
            <p>Расписание можно посмотреть на сайте La Culture à Reims: www.infoculture-reims.fr</p>
            <p>Посетите официальный сайт города:  www.reims.fr</p>
            <p>Все достопримечательности города можно найти в Le Monocle, бесплатном путеводителе по Реймсу, который выпускают студенты NEOMA BS с 1985 года! www.facebook.com/lemonocle</p>
        </template>

    </Main>
</template>

<script>
import Introduction from '@/components/city/slots/Introduction.vue'
import Main from '@/components/city/slots/Main.vue'
export default {
    components:{
        Introduction,
        Main
    }
}
</script>